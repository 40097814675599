import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import queryString from 'query-string'

import Button from '../button'

import './form.scss'
import { FREE_QUOTE_FORM } from '../../config/analytics-events'
import { encodeForm } from './utils'

const services = [
  'Commercial Cleaning',
  'Office Cleaning',
  'Medical Center Cleaning',
  'Window Cleaning',
  'Move In/Out Cleaning',
  'Carpet Cleaning',
  'Laser Cleaning',
  'Other',
]

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputAddress: '',
  inputCity: '',
  inputPromoCode: '',
  inputDescription: '',
  inputServices: services.map((service) => ({ name: service, selected: false })),
}

class FreeQuoteForm extends React.Component {
  constructor(props) {
    super(props)

    const { location } = props
    const serviceToQuote = location.search ? queryString.parse(location.search) : null
    let servicesPreSelected = emptyFields.inputServices
    if (serviceToQuote && serviceToQuote.service) {
      servicesPreSelected = servicesPreSelected.map((service) => {
        if (service.name === serviceToQuote.service) {
          return { ...service, selected: true }
        }
        return service
      })
    }
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
      inputServices: servicesPreSelected,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeService = (event) => {
    const { inputServices } = this.state
    this.setState({
      inputServices: inputServices.map((service) => {
        const { name } = service
        if (name === event.target.value) {
          return { name, selected: event.target.checked }
        }
        return service
      }),
    })
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  getServiceCheckValue = (name) => {
    const { inputServices } = this.state
    return inputServices.find((service) => service.name === name).selected
  }

  submitForm = (e) => {
    const _this = this
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName,
      inputEmail,
      inputPhone,
      inputAddress,
      inputCity,
      inputPromoCode,
      inputServices,
      inputDescription,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })
    fetch('/php/free-quote.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        emailTitle: 'Free Quote Request - HygieNZ website',
        inputName,
        inputPhone,
        inputEmail,
        inputAddress,
        inputCity,
        inputPromoCode,
        inputDescription,
        inputServices: inputServices
          .filter((service) => service.selected)
          .map((service) => service.name),
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: FREE_QUOTE_FORM.category,
            action: FREE_QUOTE_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: FREE_QUOTE_FORM.category,
          action: FREE_QUOTE_FORM.errorSubmitAction,
        })
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error || !data.success) {
          _this.setState({ contactMessage: { success: false, message: data.messageEnglish } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageEnglish } })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: FREE_QUOTE_FORM.category,
          action: FREE_QUOTE_FORM.errorSubmitAction,
        })
        this.setState({ sending: false })
      })
  }

  getServicesOptions = () => (
    <div className='form-group'>
      {services.map((service) => (
        <div key={service} className='services-options-container'>
          <input
            id={service}
            type='checkbox'
            name={service.replace(' ', '')}
            value={service}
            onChange={this.handleChangeService}
            checked={this.getServiceCheckValue(service)}
          />
          <label htmlFor={service}><b>{service}</b></label>
        </div>
      ))}
    </div>
  )


  render() {
    const {
      contactMessage,
      inputName,
      inputEmail,
      inputPhone,
      inputAddress,
      inputCity,
      inputPromoCode,
      inputDescription,
      sending,
    } = this.state
    return (
      <div className='contact-form'>
        <form id='free_quote_form' name='freeQuoteForm' data-toggle='validator' onSubmit={this.submitForm}>
          <div className='form-group'>
            <input
              value={inputName}
              onChange={this.handleChange}
              name='inputName'
              min='2'
              id='inputName'
              placeholder='Name'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputEmail}
              onChange={this.handleChange}
              name='inputEmail'
              id='inputEmail'
              placeholder='Email'
              type='email'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputPhone}
              onChange={this.handleChange}
              name='inputPhone'
              id='inputPhone'
              placeholder='Phone'
              type='text'
            />
          </div>

          <div className='form-group'>
            <input
              value={inputAddress}
              onChange={this.handleChange}
              name='inputAddress'
              id='inputAddress'
              placeholder='Address'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputCity}
              onChange={this.handleChange}
              name='inputCity'
              id='inputCity'
              placeholder='City'
              type='text'
            />
          </div>

          <div className='form-group checkbox-container'>
            <span>What kind of job do you want us to do?</span>
            {this.getServicesOptions()}
          </div>

          <div className='form-group'>
            <input
              value={inputPromoCode}
              onChange={this.handleChange}
              name='inputPromoCode'
              id='inputPromoCode'
              placeholder='Promo Code'
              type='text'
            />
          </div>

          <div className='form-group'>
            <textarea
              value={inputDescription}
              onChange={this.handleChange}
              name='inputDescription'
              id='inputDescription'
              placeholder='Description of the job'
              rows='2'
            />
          </div>

          <div className='form-group'>
            <Button isButton type='submit' disabled={sending}>
              {sending ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <div className='contact-message-container'>
            {contactMessage && contactMessage.success
              && <p className='contact-message-success'>{contactMessage.message}</p>
            }
            {contactMessage && !contactMessage.success
              && <p className='contact-message-error'>{contactMessage.message}</p>
            }
          </div>
        </form>
      </div>)
  }
}

FreeQuoteForm.propTypes = {
  location: PropTypes.object,
}

export default FreeQuoteForm
